.site-page-header {
    /* border: 1px solid rgb(235, 237, 240); */
    height: 67px;
    margin-top: -10px !important;
    position:fixed !important;
    z-index: 200;
    width: 100vw;
    background-color: rgba(200, 200, 200, 0.541) !important;
}

/* .noLine {
    border-bottom: 0px !important;

    background-repeat: no-repeat;
}

.lineTop::after {
    inset: -7px 12px auto !important;
} */