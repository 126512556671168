.box {
    background-color: hsl(0, 0%, 44%);
    width: 28px;
    height: 28px;
    border: 1px solid rgb(31, 228, 24);
    display: inline-block;
    margin: 0.5px;
    text-align: center;
    line-height: 30px;
    cursor: default;
    font-size: 12pt;
    font-weight: bold;
  }
  